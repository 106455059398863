import {createRouter, createWebHistory} from "vue-router";
import MainView from '@/components/MainView.vue'
import MenuView from "@/components/MenuView.vue";
import EditMenuView from "@/components/edit-menu/EditMenuView.vue";
import LoginView from "@/security/LoginView.vue";

let router = createRouter({
    history: createWebHistory(),
    routes: [
        {name: "home", path: "/", component: MainView},
        {name: "menu", path: "/menu", component: MenuView},
        {name: "edit-menu", path: "/edit-menu", component: EditMenuView},
        {name: "login", path: "/login", component: LoginView}
    ]
});

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem("token");
    if (token || to.name === "login") {
        next()
    } else {
        next({name: "login"})
    }
})

export default router;
