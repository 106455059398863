<script setup>
import {useMenuStore} from "@/stores/menu-store"
import MenuItem from "@/components/menu/MenuItem.vue"
import {storeToRefs} from "pinia"
import {useRouter} from 'vue-router'
import {computed, onMounted} from "vue";

let {menu, allItems} = storeToRefs(useMenuStore());
let loadMenu = useMenuStore().loadMenu;
let loadAllItems = useMenuStore().loadAllItems;
let router = useRouter();

function goToEditMenu() {
  router.push("/edit-menu")
}

onMounted(() => {loadMenu(); loadAllItems()})

let snacks = computed(() => allItems.value.filter(item => item.type !== 'beer' && item.available));
</script>

<template>
  <div id="menu">
    <template v-for="(menuItem, tap) in menu" :key="menuItem.tap">
      <MenuItem v-model="menu[tap]" class="menu-item" v-if="menuItem.name !== undefined"/>
    </template>
    <template v-for="(menuItem, index) in snacks" :key="menuItem.id">
      <MenuItem v-model="snacks[index]" class="menu-item" v-if="menuItem.name !== undefined"/>
    </template>
  </div>
  <button @click="goToEditMenu">Редактировать Меню</button>
</template>

<style scoped>
 #menu {
   margin: 1rem 0;
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 1rem;
   grid-row-gap: 1.5rem;
   justify-content: space-evenly;
   justify-items: center;
 }
</style>