import {defineStore} from 'pinia'
import {ref} from "vue";
import {makeCall} from "@/utils/network";
import {notify} from "@kyvg/vue3-notification";

export const useGuestStore = defineStore('guests', () => {

    const id = ref(0);

    const guests = ref([])

    async function loadGuests() {
        return makeCall('GET', '/guests', null, {active: true})
    }

    async function loadOpenOrder(id) {
        return makeCall('GET', `/guests/${id}/orders`)
            .then(response => response.data)
    }

    const selectedGuest = ref(undefined)

    async function toggleSelectedGuest(guest) {
        if (selectedGuest.value === undefined || guest === undefined || selectedGuest.value.id !== guest.id) {
            selectedGuest.value = guest;
            if (selectedGuest.value !== undefined) {
                selectedGuest.value.order = await loadOpenOrder(selectedGuest.value.id)
            }
        } else {
            selectedGuest.value = undefined;
        }
    }

    async function addOrderItem(guestId, orderItem) {
        return makeCall('POST', `/guests/${guestId}/orders/items`, orderItem)
            .then(response => response.data)
    }

    async function addToOrderForSelectedGuest(item, quantity) {

        if (selectedGuest.value === undefined) {
            notify({
                title: "Нужно выбрать гостя",
                text: "Заказ не будет изменён",
                type: "warn"
            })
            return
        }

        let price = Math.round(quantity * 2 * item.price)
        if (item.type !== 'beer') {
            price = price / 200
        }

        selectedGuest.value.order = await addOrderItem(
            selectedGuest.value.id,
            {
                name: item.name,
                price: price,
                quantity: quantity,
            })

        notify({
            title: `${item.name} добавлено`,
            text: `Гостю ${selectedGuest.value.name} на ${price / 100} Br`,
            type: "success"
        })
    }

    async function addGuest() {

        makeCall('POST', '/guests', {name: 'Новый гость №' + id.value++})
            .then((response) => {
                guests.value.push(response.data)
                notify({
                    title: `Добавлен гость ${response.data.name}`,
                })
            })
    }

    async function editGuest(id, name) {
        await makeCall('PATCH', `/guests/${id}`, {name: name})
    }

    async function completeOrder(guestId, orderId) {
        await makeCall('DELETE', `/guests/${guestId}/orders/${orderId}`)
    }

    async function deleteGuest(id) {
        makeCall('PATCH', `/guests/${id}/inactive`)
            .then((response) => {
                if (response.status === 200) {
                    guests.value = guests.value.filter((guest) => guest.id !== id)
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    notify({
                        title: "У этого гостя есть незакрытый заказ",
                        text: "Гость не будет удалён пока его заказ не закрыт",
                        type: "error"
                    })
                }
            })
    }

    return {
        guests,
        selectedGuest,
        addToOrderForSelectedGuest,
        toggleSelectedGuest,
        addGuest,
        deleteGuest,
        loadGuests,
        editGuest,
        completeOrder
    }
})
