<script setup>

import {ref} from "vue";
import {useSecurityStore} from "@/stores/security-store";

let username = ref("");
let password = ref("");

const login = () => useSecurityStore().login(username.value, password.value);

</script>

<template>
  <div id="login-container">
    <div id="inner-login-container">
      <span>Username:</span><input type="text" v-model="username"/>
      <span>Password:</span><input type="password" v-model="password"/>
      <div id="login-button-container">
        <button @click="login()">Login</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
}

#inner-login-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
  align-content: flex-start;
  text-align: left;
  border: 1px solid gray;
  padding: 2rem;
  margin: 1rem;
}

</style>