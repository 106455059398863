<script setup>
import {computed, defineModel, ref} from 'vue';

import {useGuestStore} from "@/stores/guests-store";
import {BACKEND_URL} from "@/config/config";

let orderThis = useGuestStore().addToOrderForSelectedGuest;

let item = defineModel({required: true})

// let ibu = computed(() => {
//   if (item.value.ibu === undefined) return "N/A"
//   if (item.value.ibu === null) return "No"
//   else return item.value.ibu
// })

let itemSelected = ref(false)

let volume = ref(0.5)
let count = ref(50)

const resetVolume = () => {
  volume.value = 0.5
  count.value = 50
}

const selectItem = () => {
  itemSelected.value = true
}
const unselectItem = () => {
  itemSelected.value = false
  resetVolume()
}

const increaseVolume = () => {
  if (volume.value === 0.3) {
    volume.value = 0.5
    return
  }
  volume.value += 0.5
}

const decreaseVolume = () => {
  if (volume.value === 0.3) {
    return
  }
  if (volume.value === 0.5) {
    volume.value = 0.3
    return
  }
  volume.value -= 0.5
}

const increaseCount = () => {
  count.value += 50
}

const decreaseCount = () => {
  if (count.value === 50) {
    return
  }
  count.value -= 50
}

const selectVolume = (item, volume) => {
  orderThis(item, volume)
  unselectItem()
}

let imagePath = computed(() => {
  if (item.value.image != null) {
    return BACKEND_URL + '/images/' + item.value.image
  }
  return ''
})

</script>

<template>
  <div class="menu-item" @click="selectItem">
    <div class="menu-item-main-box">
      <span class="menu-item-tap">{{ item.tap }}</span>
      <span class="menu-item-price">{{ item.price / 100 }} Br</span>
    </div>
    <div class="menu-item-logo">
      <img v-if="item.image" :src="imagePath" alt="logo">
      <img v-else src="../../assets/no_image.jpg" alt="logo">
    </div>
    <div class="menu-item-desc">
      <span class="menu-item-name">{{ item.name }}</span>
    </div>
    <div class="menu-item-overlay" v-show="itemSelected && item.type === 'beer'">
      <div class="menu-item-volume-wrapper">
        <span class="menu-item-volume-button" @click.stop="decreaseVolume()">-</span>
        <span class="menu-item-volume-value">{{ volume }}</span>
        <span class="menu-item-volume-button" @click.stop="increaseVolume()">+</span>
      </div>
      <div class="menu-item-button menu-item-button-add" @click.stop="selectVolume(item, volume)">Add</div>
      <div class="menu-item-button menu-item-button-cancel" @click.stop="unselectItem">Cancel</div>
    </div>
    <div class="menu-item-overlay" v-show="itemSelected && item.type !== 'beer'">
      <div class="menu-item-volume-wrapper">
        <span class="menu-item-volume-button" @click.stop="decreaseCount()">-</span>
        <span class="menu-item-volume-value">{{ count }}</span>
        <span class="menu-item-volume-button" @click.stop="increaseCount()">+</span>
      </div>
      <div class="menu-item-button menu-item-button-add" @click.stop="selectVolume(item, count)">Add</div>
      <div class="menu-item-button menu-item-button-cancel" @click.stop="unselectItem">Cancel</div>
    </div>
  </div>
</template>

<style scoped>

.menu-item {
  position: relative;
}

.menu-item-main-box {
  padding: 0 1rem;
  font-size: 1.2rem;
  color: gold;
}

.menu-item-tap {
  float: left;
}

.menu-item-price {
  float: right;
  text-align: center;
}

.menu-item-logo > img {
  width: 100%;
}

.menu-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 2rem;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.menu-item-volume-wrapper {
  display: grid;
  grid-template-columns: 2rem auto 2rem;
  grid-gap: 1rem;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  color: yellow;
}

.menu-item-logo {
  min-height: 200px;
}

.menu-item-button {
  z-index: 2;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  color: yellow;
}

.menu-item-button-cancel {
  grid-column: 1 / -1;
}

.menu-item-name {
  padding-left: .5rem;
}
</style>