<script setup>
import {computed, defineModel, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faBars, faCircleDown, faCircleUp, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {useMenuStore} from "@/stores/menu-store";

let item = defineModel({required: true})
let enableItem = async () => {
  useMenuStore().enableItem(item.value.id)
      .then((res) => item.value = res.data)
}
let disableItem = async () => {
  useMenuStore().disableItem(item.value.id)
      .then((res) => item.value = res.data)
}

let classes = computed(() => {
  if (item.value.tap != null) return ['on-tap']
  if (item.value.available == true) return ['available']
  return ['disabled']
})

let showEdit = ref(false)

let toggleEdit = () => showEdit.value = !showEdit.value

const saveChanges = () => {
  item.value.type = item.value.isBeer ? 'beer' : 'snack'
  if (item.value.id !== undefined) {
    useMenuStore().updateItem(item.value)
    if (file.value) {
      useMenuStore().uploadImage(item.value.id, file.value)
      file.value = null
    }
    useMenuStore()
  } else {
    useMenuStore().addItem(item.value)
  }
  toggleEdit()
}

const deleteItem = () => {
  useMenuStore().deleteItem(item.value)
}

let file = ref(null)

function onFileChanged($event) {
  const target = $event.target;
  if (target && target.files) {
    file.value = target.files[0];
  }
}
</script>

<template>
  <div>
    <div class="edit-menu-item-header" :class="classes">
      <span>{{ item.id }}.</span>
      <span>{{ item.name }}</span>
        <span v-if="item.tap != null">&nbsp;</span>
        <span class="menu-item-button" v-else-if="item.available && item.id != null" @click="disableItem(item)"><FontAwesomeIcon :icon="faCircleDown"></FontAwesomeIcon></span>
        <span class="menu-item-button" v-else-if="item.id !== undefined" @click="enableItem(item)"><FontAwesomeIcon :icon="faCircleUp"></FontAwesomeIcon></span>
      <span><FontAwesomeIcon :icon="faBars" @click="toggleEdit" class="menu-item-button" ></FontAwesomeIcon></span>
      <span><FontAwesomeIcon :icon="faTrashCan" @click="deleteItem()" class="menu-item-button"></FontAwesomeIcon></span>
    </div>
    <div class="edit-menu-item" v-show="showEdit">
      <div class="edit-menu-item-row">
        <span class="edit-menu-item-row-title">Название</span>
        <input type="text" v-model="item.name"/>
      </div>
      <div class="edit-menu-item-row">
        <span class="edit-menu-item-row-title">Стиль</span>
        <input type="text" v-model="item.style" :disabled="!item.isBeer"/>
      </div>
      <div class="edit-menu-item-row">
        <span class="edit-menu-item-row-title">ABV</span>
        <input type="text" v-model="item.abv" :disabled="!item.isBeer"/>
      </div>
      <div class="edit-menu-item-row">
        <span class="edit-menu-item-row-title">IBU</span>
        <input type="text" v-model="item.ibu" :disabled="!item.isBeer"/>
      </div>
      <div class="edit-menu-item-row">
        <span class="edit-menu-item-row-title">Пивоварня</span>
        <input type="text" v-model="item.brewery" :disabled="!item.isBeer"/>
      </div>
      <div class="edit-menu-item-row">
        <span class="edit-menu-item-row-title">Цена</span>
        <input type="text" v-model="item.price"/>
      </div>
      <div class="edit-menu-item-row">
        <span class="edit-menu-item-row-title">Пиво?</span>
        <input type="checkbox" id="is-beer" v-model="item.isBeer" />
      </div>
      <div class="edit-menu-item-row" v-show="item.id !== undefined">
        <span class="edit-menu-item-row-title">Логотип</span>
        <input
            type="file"
            @change="onFileChanged($event)"
            accept="image/*"
            capture
        />
      </div>
      <div>
        <button class="save-changes" @click="saveChanges()">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.edit-menu-item {
  display: grid;
  grid-row-gap: .5rem;
  margin: .5rem;
}

.edit-menu-item-row {
  display: grid;
  grid-template-columns: 15% 80%;
  grid-column-gap: 5%;
}

.edit-menu-item-header {
  margin: 1rem;
  border: 1px solid gray;
  padding: .5rem;
  display: grid;
  grid-template-columns: 1fr 10fr repeat(3, 1fr);
  grid-column-gap: .2rem;
  justify-content: center;
  color: black;
}

.on-tap {
  background-color: green;
}

.available {
  background-color: lightgray;
}

.disabled {
  background-color: blueviolet;
}

.save-changes {
  column-count: 2;
  width: 100%;
}

.menu-item-button {
  cursor: pointer;
}
</style>