import {defineStore} from 'pinia';
import {ref} from "vue";
import {makeCall, uploadFile} from "@/utils/network";

export const useMenuStore = defineStore('menu', () => {

    const menu = ref({})
    const availableBeers = ref([])
    const allItems = ref([])

    const loadMenu = async () => {
        makeCall("GET", "/menu/items")
            .then(res => menu.value = res.data)
    }

    const loadAllItems = async () => {
        makeCall("GET", "/menu/items/all")
            .then(res => {
                allItems.value = res.data
                for (const item of allItems.value) {
                    item.isBeer = (item.type === 'beer')
                }
            })
    }

    const loadAvailableItems = async () => {
        makeCall("GET", "/menu/items/available")
            .then(res => availableBeers.value = res.data)
    }

    const assignToTap = async (tap, beerId) => {
        makeCall("POST", `/menu/items/taps/${tap}/beers/${beerId}`)
            .then(res => {
                menu.value = res.data
                loadAllItems()
            })
    }

    const removeFromTap = async (tap) => {
        makeCall("DELETE", `/menu/items/taps/${tap}`)
            .then(res => {
                menu.value = res.data
                loadAllItems()
            })
    }

    const enableItem = async (itemId) => {
        return makeCall("PATCH", `/menu/items/${itemId}/enable`)
    }

    const disableItem = async (itemId) => {
        return makeCall("PATCH", `/menu/items/${itemId}/disable`)
    }

    const addItem = async (item) => {

        makeCall('POST', `/menu/items`, item)
            .then(res => {
                allItems.value.push(res.data)
                removeItemsWithoutId();
            })
    }

    const updateItem = async (item) => {

        makeCall('PUT', `/menu/items/${item.id}`, item)
            .then(res => {
                let updatedItem = res.data;
                replaceItemById(updatedItem);
            })
    }

    function replaceItemById(updatedItem) {
        let index = allItems.value.findIndex((item) => item.id === updatedItem.id);
        allItems.value[index] = updatedItem
    }

    function removeItemsById(itemId) {
        allItems.value = allItems.value.filter(item => item.id !== itemId)
    }

    function removeItemsWithoutId() {
        allItems.value = allItems.value.filter(item => item.id !== undefined)
    }

    const deleteItem = async (item) => {

        if (item.id == null) {
            removeItemsWithoutId()
            return
        }

        makeCall('DELETE', `/menu/items/${item.id}`)
            .then(() => removeItemsById(item.id))
    }

    const uploadImage = async (itemId, file) => {

        let formData = new FormData();
        formData.append('file', file)
        await uploadFile('POST', `/menu/items/${itemId}/image`, formData)
    }

    return {
        menu,
        availableBeers,
        allItems,
        loadMenu,
        assignToTap,
        removeFromTap,
        loadAvailableItems,
        loadAllItems,
        enableItem,
        disableItem,
        addItem,
        updateItem,
        deleteItem,
        uploadImage,
    }
});
