<script setup>
import GuestOrder from "@/components/guests/GuestOrder.vue";
import {useGuestStore} from "@/stores/guests-store";
import {storeToRefs} from "pinia";
import MenuView from "@/components/MenuView.vue";
import LoginButton from "@/security/LoginButton.vue";
import GuestsList from "@/components/guests/GuestsList.vue";

let store = useGuestStore();
let {selectedGuest} = storeToRefs(store);
</script>

<template>
  <div id="main-wrapper">
    <LoginButton/>
    <GuestsList/>
    <GuestOrder v-if="selectedGuest" v-model="selectedGuest"/>
    <MenuView/>
  </div>
</template>

<style scoped>
#main-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
