import { createApp } from 'vue'
import router from './router/router'
import {createPinia} from "pinia";
import App from './App.vue'
import {Notifications} from "@kyvg/vue3-notification";

createApp(App)
    .use(router)
    .use(createPinia())
    .use(Notifications)
    .mount('#app')
