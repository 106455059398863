import axios from "axios";
import {BACKEND_URL} from "@/config/config";
import {notify} from "@kyvg/vue3-notification";
import router from "@/router/router";

axios.interceptors.response.use(
    async response => {
        return response
    },
    async error => {
        if (error.response.status === 401) {
            notify({
                title: "Войдите ещё раз",
                type: "warn"
            })
            await router.push("/login")
        }
        return Promise.reject(error)
    })

export const makeCall = async (method, path, data, params) => {
    const isLoggedIn = localStorage.getItem('token') !== undefined;
    if (!isLoggedIn) {
        notify({
            title: "Пользователь не авторизован",
            type: "error",
        })
        await router.push("/login");
    }
    return axios.request({
        url: path,
        method: method,
        baseURL: BACKEND_URL,
        transformRequest: (data) => {
            return JSON.stringify(data, null, 2);
        },
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        params: params,
        data: data
    })
}

export const uploadFile = async (method, path, data, params) => {
    const isLoggedIn = localStorage.getItem('token') !== undefined;
    if (!isLoggedIn) throw Error("User isn't logged in");
    return axios.request({
        url: path,
        method: method,
        baseURL: BACKEND_URL,
        headers: {
            "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        params: params,
        data: data
    })
}

export default {makeCall, uploadFile}
