<script setup>

import {useSecurityStore} from "@/stores/security-store";

const logout = () => useSecurityStore().logout()

</script>

<template>
  <div class="login-container">
    <button @click="logout">Выйти</button>
  </div>
</template>

<style scoped>
.login-container {
  text-align: right;
  padding: 0.5rem;
}
</style>