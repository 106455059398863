<script setup>
import {defineModel, ref} from "vue";
import {useGuestStore} from "@/stores/guests-store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faTrashCan, faPenToSquare, faSquareCheck} from "@fortawesome/free-solid-svg-icons";

let deleteGuest = useGuestStore().deleteGuest;

let guest = defineModel({required: true});
let isRedacted = ref(false);
const editGuest = () => {
  isRedacted.value = !isRedacted.value
}
const renameGuest = (id, name) => {
  editGuest()
  useGuestStore().editGuest(id, name)
}
</script>

<template>
  <div class="guest-item-wrapper">
    <span class="guest-item-name" v-if="!isRedacted">{{ guest.name }}</span>
    <input v-else type="text"
           class="guest-item-name"
           v-model="guest.name"
           @keyup.enter="renameGuest(guest.id, guest.name)"
           @keyup.esc="editGuest()"/>
    <button v-if="!isRedacted" class="guest-item-edit-button" @click="editGuest()">
      <FontAwesomeIcon :icon="faPenToSquare"/>
    </button>
    <button v-else class="guest-item-edit-button"
            @click="renameGuest(guest.id, guest.name)">
      <FontAwesomeIcon :icon="faSquareCheck"/>
    </button>
    <button class="guest-item-delete-button" @click.stop="deleteGuest(guest.id)">
      <FontAwesomeIcon :icon="faTrashCan"/>
    </button>
  </div>
</template>

<style scoped>

.guest-item-wrapper {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  justify-content: space-evenly;
}

.guest-item-name {
  width: 100%;
  height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
}

span.guest-item-name {
  cursor: pointer;
}

</style>
