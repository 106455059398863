<script setup>
import {computed, defineProps, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faMinusSquare, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/stores/menu-store";

let emit = defineEmits(['assignBeerToTap', 'removeBeer']);
let model = defineProps({number: Number, beerName: String})
let hasBeer = computed(() => model.beerName !== null)
let showList = ref(false)
let selectedBeerId = ref(null)
let {availableBeers} = storeToRefs(useMenuStore())

const addToTap = () => {
  useMenuStore().loadAvailableItems()
  showList.value = true
}

const madeSelectOfBear = () => {
  showList.value = false
  emit('assignBeerToTap', [model.number, selectedBeerId.value])
  selectedBeerId.value = null
}

let itemClasses = computed(() => {
  return (hasBeer.value) ? ['has-beer'] : ['has-no-beer']
})
</script>

<template>
  <div class="tap-item" :class="itemClasses">
    <span class="tap-label">{{ beerName }}</span>
    <span class="tap-number">{{ number }}</span>
    <template v-if="hasBeer">
      <span class="tap-button"><FontAwesomeIcon :icon="faMinusSquare" @click="$emit('removeBeer', number)"></FontAwesomeIcon></span>
    </template>
    <template v-else>
      <span class="tap-button"><FontAwesomeIcon :icon="faPlusSquare" @click="addToTap()"></FontAwesomeIcon></span>
    </template>
    <select v-show="showList" v-model="selectedBeerId" @change="madeSelectOfBear()" class="tap-beer-select">
      <option :value="null" selected></option>
      <option v-for="availableBeer in availableBeers" :key="availableBeer.id" :value="availableBeer.id">{{availableBeer.name}}</option>
    </select>
  </div>
</template>

<style scoped>
  .tap-item {
    border: 1px solid gray;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: stretch;
    align-items: center;
    padding: 1rem;
  }

  .tap-button > svg {
    font-size: 2rem;
    text-align: left;
    cursor: pointer;
    z-index: 100;
  }

  .tap-label, .tap-beer-select {
    font-weight: bold;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .tap-number {
    font-size: 2rem;
    font-weight: bold;
    border: 1px solid gray;
    border-radius: 7px;
    margin: 1rem;
  }

  .has-beer {
    border-color: green;
    border-width: medium;
  }

  .has-no-beer {
    border-color: red;
    border-width: medium;
  }
</style>