<script setup>
import GuestItem from "@/components/guests/GuestItem.vue";
import {useGuestStore} from "@/stores/guests-store";
import {storeToRefs} from "pinia";
import {onBeforeMount} from "vue";

let {guests} = storeToRefs(useGuestStore())
onBeforeMount(async () => {
  let loadedGuests = await useGuestStore().loadGuests();
  guests.value.splice(0, guests.value.length);
  guests.value.push(...loadedGuests.data);
});

let addGuest = useGuestStore().addGuest
const toggleSelectedGuest = useGuestStore().toggleSelectedGuest

</script>

<template>
  <div id="guests-box">
    <template v-for="(guest, index) in guests" :key="guest.id">
      <GuestItem v-model="guests[index]" class="guest-item" @click="toggleSelectedGuest(guests[index])"></GuestItem>
    </template>
  </div>
  <button id="add-guest" @click="addGuest">Добавить Гостя</button>
</template>

<style scoped>
  #guests-box {
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-evenly;
    column-gap: 1rem;
  }
  #add-guest {
    width: 100%;
    padding: .5rem;
  }
</style>