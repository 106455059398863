<script setup>
import {onMounted, ref} from "vue";
import {useMenuStore} from "@/stores/menu-store"
import {storeToRefs} from "pinia"
import EditMenuItem from "@/components/edit-menu/EditMenuItem.vue";
import router from "@/router/router";
import TapItem from "@/components/menu/TapItem.vue";

let {menu, allItems} = storeToRefs(useMenuStore());
onMounted(() => {
  useMenuStore().loadMenu()
  useMenuStore().loadAllItems()
})

const taps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

function getBeerName(tap) {
  let beer = menu.value[tap];
  if (beer === undefined || beer.name === undefined) return null
  return beer.name;
}

const removeBeer = (tap) => {
  useMenuStore().removeFromTap(tap)
}

const assignBeerToTap = ($event) => {
  let tapId = $event[0];
  let beerId = $event[1];
  if (beerId == null) return
  useMenuStore().assignToTap(tapId, beerId)
}

const addBeer = () => {
  allItems.value.push({name: 'Новое пиво', isBeer: true})
}

let hideUnavailable = ref(true)

</script>

<template>
  <div id="admin-menu-container">
    <div id="taps">
      <template v-for="tap in taps" :key="tap">
        <TapItem
            :number="tap"
            :beer-name="getBeerName(tap)"
            @remove-beer="removeBeer(tap)"
            @assign-beer-to-tap="assignBeerToTap($event)"
            >
        </TapItem>
      </template>
    </div>
    <div id="menu">
      <button @click="addBeer()" id="add-beer">Добавить</button>
      <div id="hide-unavailable-group">
        <label for="hide-unavailable">Скрыть недоступные</label>
        <input type="checkbox" v-model="hideUnavailable" id="hide-unavailable"/>
      </div>
      <template v-for="(menuItem, index) in allItems" :key="menuItem.tap">
        <EditMenuItem v-model="allItems[index]" class="menu-item" v-if="(menuItem.available || !hideUnavailable || menuItem.id == null) && menuItem.name !== undefined"/>
      </template>
    </div>
    <button class="close-button" @click="router.push('/')">Закрыть</button>
  </div>
</template>

<style scoped>
#menu {
  text-align: left;
  margin: .5rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-evenly;
  justify-items: stretch;
  align-content: space-evenly;
  align-items: stretch;
}

#taps {
  padding: .5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: stretch;
  align-content: stretch;
  grid-gap: 1rem;
}

#add-beer {
  margin: 1rem;
  font-weight: bold;
}

#hide-unavailable-group {
  padding-left: 1rem;
}

.close-button {
  width: 100%;
  height: 2rem;
}

@media screen and (min-width: 1000px) {
  #menu {
    grid-template-columns: repeat(4, 1fr);
  }
  #taps {
    grid-template-columns: repeat(5, 1fr);
  }
}

</style>