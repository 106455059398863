<script setup>

import {computed, defineModel} from "vue";
import {useGuestStore} from "@/stores/guests-store";
import {format} from "date-fns/format";

let guest = defineModel({required: true})

let toggleSelectedGuest = useGuestStore().toggleSelectedGuest;

let itemTotal = (item) => item.price

let total = computed(() => {
  if (guest.value.order == null) return 0
  let subTotals = guest.value.order.items.map((item) => itemTotal(item))
  return subTotals.reduce((a, b) => a + b, 0)
})

let closeOrder = async () => {
  await useGuestStore().completeOrder(guest.value.id, guest.value.order.id)
  await toggleSelectedGuest(undefined)
}
</script>

<template>
  <div id="order-box">
    <span class="guest-name">{{ guest.name }}</span>
    <ul v-if="guest.order != null" class="guest-order-list">
      <li>
        <span class="order-item-name">Наименование</span>
        <span class="order-item-count">Сколько</span>
        <span class="order-item-price">Цена</span>
        <span class="order-item-time">Время</span>
      </li>
      <li v-for="orderItem in guest.order.items" :key="orderItem.id">
        <span class="order-item-name">{{ orderItem.name }}</span>
        <span class="order-item-count">{{ orderItem.quantity }}</span>
        <span class="order-item-price">{{ orderItem.price / 100 }}</span>
        <span class="order-item-time">{{ format(orderItem.time, 'HH:mm') }}</span>
      </li>
    </ul>
    <div class="order-total">
      <span>Итого: </span>
      <span>{{ total / 100 }}</span>
    </div>
    <div class="close-order">
      <button @click="closeOrder" v-if="guest.order">Закрыть чек</button>
    </div>
  </div>
</template>

<style scoped>
#order-box {
  margin: 1rem 0;
}

.guest-name {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}

ul, li {
  padding: 0 .5rem;
}

.guest-order-list > li:first-child {
  font-weight: bold;
  margin-bottom: .5rem;
}

.guest-order-list > li {
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  justify-content: space-evenly;
  grid-gap: .5rem;
  text-overflow: ellipsis;
}

.order-total {
  text-align: right;
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 1rem;
}

.close-order, .close-order > button {
  width: 100%;
}

</style>