import {defineStore} from "pinia";
import {ref} from "vue";
import axios from "axios";
import qs from "qs";
import {BACKEND_URL} from "@/config/config";
import router from "@/router/router";
import {notify} from "@kyvg/vue3-notification";

export const useSecurityStore = defineStore('security', () => {

    const token = ref(null);

    async function login(username, password) {
        await axios.post(
            `${BACKEND_URL}/token`,
            qs.stringify({
                grant_type: 'password',
                username: username,
                password: password
            }),
            {
                headers: [
                    {"Content-Type": "application/x-www-form-urlencoded; charset=utf-8"},
                    {"Accept": "application/json"}
                ],
            }
        )
            .then(response => {
                let t = response.data.access_token;
                localStorage.setItem('token', t);
                token.value = t;
                router.push('/');
                notify({
                    title: "Вход успешен"
                })
            })
            .catch(error => {
                if (error.response.status === 401) {
                    notify({
                        title: "Вход не выполнен",
                        text: "Логин или пароль не верны",
                        type: "error"
                    })
                } else {
                    notify({
                        title: "Вход не выполнен",
                        text: "Что-то пошло не так. Попробуйте позже",
                        type: "error"
                    })
                }
            })
    }

    async function logout() {
        token.value = null
        localStorage.removeItem('token');
        await router.push("/login")
    }

    return {token, login, logout};
})
